@import 'vars';
@import 'typography';

html {
  font-size: 16px;

  @media screen and (max-width: 1600px) {
    font-size: 15px;
  }

  @media screen and (max-width: 1400px) {
    font-size: 14px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  font-family: var(--theme-font-family-base);
  text-rendering: optimizeLegibility;
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* > * {
  box-sizing: inherit;
}

body {
  @include typography-crm-regular;
  box-sizing: border-box;
  color: var(--theme-text-main);

  /* total width */
  ::-webkit-scrollbar {
    background: inherit;
    width: 12px;
    height: 12px;

    @media screen and (max-width: 899px) {
      width: 0;
    }
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: transparent;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: #9d9d9d;

    @media screen and (max-width: 899px) {
      width: 0;
    }

    &:vertical {
      border-right: 4px solid rgba(0, 0, 0, 0);
    }

    &:horizontal {
      border-bottom: 4px solid rgba(0, 0, 0, 0);
    }
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}

button {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

input {
  font-family: inherit;
  font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.subtitle {
  @include typography-crm-h2;

  strong {
    color: var(--theme-accent);
    margin-left: 1rem;
  }
}

.text-medium {
  @include typography-crm-medium;
}

.subtitle-2 {
  @include typography-subtitle;
}

a {
  color: var(--theme-accent);
}

._mirror-horizontal {
  transform: scaleX(-1);
}
