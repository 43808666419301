@import 'typography';

.table-page {
  .search {
    flex: 1 1 auto;
    max-width: 20rem;
    min-width: 10rem;
  }

  .filters {
    display: flex;
    align-items: center;
    padding-bottom: 1.25rem;
    gap: 1.5rem;
    flex-wrap: wrap;

    &__controls {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;
    }

    &__actions {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      gap: 1.5rem;
      flex-wrap: wrap;

      &._right {
        justify-content: flex-end;
      }

      .app-icon-button {
        &:not(:hover):not(:active) {
          color: #bbbbbb;
        }
      }

      .icon-actions {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      app-columns-config-panel {
        display: none;
      }
    }

    ._right {
      margin-left: auto;
    }
  }
}
