@import 'typography';

.news-input-control {
  flex: 1 1 auto;
  outline: none;
  resize: none;
  appearance: none;
  -webkit-appearance: none;
  word-wrap: break-word;
  overflow: hidden;
  vertical-align: top;
  user-select: text;
  cursor: text;
  white-space: pre-wrap;
  @include typography-text-3;

  a {
    color: var(--theme-accent);
    text-decoration: none;
  }

  .emoji {
    width: 16px;
    height: 16px;
    border: 0;
    vertical-align: -3px;
    margin: 0 1px;
    display: inline-block;
    overflow: hidden;
    content: '';
  }

  .mention {
    font-weight: 600;
  }
}
