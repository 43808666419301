@import 'typography';

.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page-header {
  display: flex;
  align-items: center;
  width: 100%;
  flex: 0 0 auto;
  margin-bottom: 0.75rem;
  padding: 0 1.875rem;
}

.page-title {
  @include typography-crm-h1;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 1rem;

  b {
    color: var(--theme-text-accent);
    font-weight: inherit;
  }
}

.page-title-badge {
  @include typography-table-text;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background: white;

  &._success {
    color: white;
    background: #4cc474;
  }

  &._danger {
    color: white;
    background: #ee4748;
  }
}

.page-card {
  flex: 1 1 auto;
  width: 100%;
  background: var(--theme-card-bg);
  border-radius: 0.625rem;
  padding: 1.5rem 2rem;
}

.table-page-card {
  .search {
    width: 15rem;
  }

  .filters {
    display: flex;
    align-items: center;
    padding-bottom: 1.25rem;
    gap: 1.5rem;

    &__controls {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &__actions {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    ._right {
      margin-left: auto;
    }
  }
}

.tab-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    flex: 0 0 auto;
    margin-bottom: 1.25rem;
    display: flex;
    align-items: center;
  }

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    .app-icon-button {
      color: var(--grey-5);
    }

    .app-flat-button {
      height: 2rem;
    }
  }

  &__body {
    flex: 1 1 auto;
  }

  &__footer {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
