@import 'typography';

body {
  /* Mat Form Field */
  .mdc-text-field {
    .mdc-text-field__input {
      caret-color: var(--theme-input-focused);
    }

    &:not(.mdc-text-field--disabled) {
      .mdc-floating-label {
        color: var(--theme-input-placeholder);

        &.mdc-floating-label--float-above {
          color: var(--theme-input-label);
        }
      }
    }

    &--outlined {
      .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing {
          border-radius: 0;
        }

        &__notch {
          width: auto !important;
        }
      }

      &:not(.mdc-text-field--disabled) {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: var(--theme-input-border);
        }

        &.mdc-text-field--focused {
          .mdc-notched-outline {
            &__leading,
            &__notch,
            &__trailing {
              border-color: var(--theme-input-focused);
            }
          }
        }

        &:not(.mdc-text-field--focused):hover {
          .mdc-notched-outline {
            &__leading,
            &__notch,
            &__trailing {
              border-color: var(--theme-input-focused) !important;
            }
          }
        }
      }
    }
  }

  .mat-form-field-invalid:not(.mdc-text-field--disabled) {
    .mdc-floating-label {
      color: var(--theme-text-error) !important;
    }
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    &:not(.mdc-floating-label--float-above) {
      top: 50%;
    }
  }

  .mat-mdc-form-field-error {
    color: var(--theme-text-error);
  }

  .mat-mdc-form-field-infix {
    min-height: 2.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include typography-input;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .mat-mdc-select-arrow-wrapper {
    height: 1rem;
  }

  .mat-mdc-form-field {
    --mat-mdc-form-field-floating-label-scale: 1;

    .mdc-text-field--outlined {
      &,
      &.mdc-notched-outline--upgraded,
      .mdc-notched-outline--upgraded {
        .mdc-floating-label--float-above {
          @include typography-overline;
          background: var(--theme-card-bg);
        }
      }
    }
  }
}

/* WebKit, Edge */
::-webkit-input-placeholder {
  color: var(--theme-input-placeholder);
}

/* Firefox 4-18 */
:-moz-placeholder {
  color: var(--theme-input-placeholder);
  opacity: 1;
}

/* Firefox 19+ */
::-moz-placeholder {
  color: var(--theme-input-placeholder);
  opacity: 1;
}

/* IE 10-11 */
:-ms-input-placeholder {
  color: var(--theme-input-placeholder);
}

/* Edge */
::-ms-input-placeholder {
  color: var(--theme-input-placeholder);
}

/* MODERN BROWSER */
::placeholder {
  color: var(--theme-input-placeholder);
}
