@import 'styles/fonts';
@import 'styles/material-theme';

@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';

@import '@ctrl/ngx-emoji-mart/picker';
@import 'cropperjs';

@import 'styles/typography-classes';
@import 'styles/general';
@import 'styles/theme/default';
@import 'styles/modify-material';
@import 'styles/components';
@import 'styles/pages';
