@import '../mixins/typography';
@import '../mixins/breakpoints';

.cdk-dialog-container {
  max-height: 100vh !important;
  overflow: auto;
  outline: none;
  display: flex !important;
  flex-direction: column;
  background: transparent;
  border-radius: 0;

  @include media-breakpoint-down(sm) {
    width: 100vw !important;
    height: var(--viewport-height) !important;
  }

  @include media-breakpoint-up(md) {
    height: auto;
  }

  > * {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(black, 0.5);
}

.dialog {
  max-width: 100%;
  width: 100%;
  padding: 1.5rem 1rem;
  overflow-x: hidden;
  background: white;
  border-radius: 0;
  box-shadow: 2px 0 40px 0 #22222233;
  flex: 1 1 auto;

  @include media-breakpoint-up(md) {
    border-radius: 1.25rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 1.875rem 1.875rem 3.75rem;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 1.75rem;
    }
  }

  &__title {
    @include typography-h7;
    color: var(--theme-text-heading);
  }

  .close-btn {
    margin-left: auto;
    color: var(--theme-text-secondary);
  }

  &__footer {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .app-flat-button {
      width: 100%;
      height: 2.5rem;

      @include media-breakpoint-up(md) {
        width: 11.875rem;
      }
    }
  }
}

body.dialog-opened {
  overflow: hidden;
}
