@import "typography";

.h1, h1 {
  @include typography-h1;
}

.h2, h2 {
  @include typography-h2;
}

.h3, h3 {
  @include typography-h3;
}

.h4, h4 {
  @include typography-h4;
}

.h5, h5 {
  @include typography-h5;
}

.h6, h6 {
  @include typography-h6;
}

.h6, h7 {
  @include typography-h6;
}

.subtitle-1 {
  @include typography-subtitle-1;
}

.subtitle-2 {
  @include typography-subtitle-2;
}

.typography-body-1 {
  @include typography-body-1;
}

.typography-body-2 {
  @include typography-body-2;
}

.typography-button {
  @include typography-button;
}

.typography-caption {
  @include typography-caption;
}

.typography-overline {
  @include typography-overline;
}
