@import '../vars/palette';

body {
  --theme-font-family-base: 'Roboto', 'Open Sans', sans-serif;
  --theme-font-family-logo-subtitle: 'Sofia Sans';
  --theme-font-family-platform: 'Open Sans';

  --grey: #f6f6f6;
  --grey-2: #dfdfdf;
  --grey-3: #f7f8fa;
  --grey-4: #f0f2f7;
  --grey-5: #bbbbbb;
  --grey-bg: #f4f4f4;

  --blue-2: #3593d1;

  --no-photo-bg-color: #c5cfda;

  /* Base text */
  --theme-primary: #039999;
  --theme-text-accent: #039999;
  --theme-accent: var(--theme-text-accent, #039999);
  --theme-text-main: #2f414a;
  --theme-text-dark: #2f414a;
  --theme-text-secondary: #939191;
  --theme-text-secondary-2: #738297;
  --theme-text-third: var(--theme-text-secondary-2);
  --theme-text-error: #ee4748;
  --theme-text-danger: #ee4748;
  --theme-text-red: #ee4748;
  --theme-dark-light: #dee5ed;
  --theme-light-green: #dcf5f5;
  --theme-yellow: #fffbe8;
  --theme-yellow-2: #f2b000;
  --theme-blue: #066d81;
  --theme-text-green: #279b4e;

  /* Alerts */
  --theme-alert-danger-bg: #f1dbda;
  --theme-alert-danger-color: #a90e46;

  --theme-alert-info-bg: #e0e0e0;
  --theme-alert-info-color: var(--theme-text-main);

  --theme-alert-success-bg: #d5ecd6;
  --theme-alert-success-color: #1f8b24;

  --theme-alert-warn-bg: #f0e5d7;
  --theme-alert-warn-color: #bf710f;

  --theme-info-panel-color: #818d93;

  /* Layout */
  --theme-main-bg: var(--grey-3);
  --theme-card-bg: white;
  --theme-lines: var(--grey-bg);

  /* Scrollbar */
  --theme-scrollbar-bg: var(--grey);
  --theme-scrollbar-thumb: var(--theme-primary);

  /* Input */
  --theme-input-border: var(--grey-2);
  --theme-input-focused: var(--theme-primary);
  --theme-input-placeholder: var(--theme-text-secondary);
  --theme-input-label: var(--theme-text-secondary);
  --theme-input-photo-bg: var(--theme-input-border);

  /** Toolbar */
  --theme-toolbar-bg: white;
  --theme-toolbar-color: var(--theme-text-main);

  /** Sidebar */
  --theme-sidebar-bg: var(--theme-card-bg);
  --theme-sidebar-color: #2f414a;
  --theme-sidebar-active: var(--theme-text-accent);

  /** Buttons */
  --theme-button-disabled-color: var(--theme-input-border);
  --theme-icon-button-bg: #ffffff;
  --theme-icon-button-color: #5f676b;
  --theme-icon-button-hover-bg: linear-gradient(180deg, #46585c 0%, #0c1517 100%);
  --theme-icon-button-hover-color: var(--theme-icon-button-bg);
  --theme-icon-button-shadow: 0 6px 20px 0 #3838381f;

  --theme-flat-button-border-radius: 1.75rem;
  --theme-flat-button-primary-bg: var(--theme-accent);
  --theme-flat-button-primary-color: var(--grey-4);
  --theme-flat-button-primary-border: transparent;
  --theme-flat-button-primary-hover-bg: var(--theme-flat-button-primary-bg);
  --theme-flat-button-primary-hover-color: var(--theme-flat-button-primary-color);
  --theme-flat-button-primary-hover-border: var(--theme-flat-button-primary-border);
  --theme-flat-button-primary-hover-opacity: 0.75;
  --theme-flat-button-primary-disabled-bg: var(--grey-2);
  --theme-flat-button-primary-disabled-color: #ffffff;
  --theme-flat-button-primary-disabled-border: var(--theme-flat-button-primary-border);
  --theme-flat-button-primary-disabled-opacity: 1;

  --theme-flat-button-white-bg: white;
  --theme-flat-button-white-color: var(--theme-text-main);
  --theme-flat-button-white-border: transparent;
  --theme-flat-button-white-disabled-bg: var(--grey-2);
  --theme-flat-button-white-disabled-color: #ffffff;
  --theme-flat-button-white-disabled-border: var(--theme-flat-button-white-border);
  --theme-flat-button-white-disabled-opacity: 1;

  --theme-flat-button-secondary-bg: var(--grey-4);
  --theme-flat-button-secondary-color: var(--theme-text-main);
  --theme-flat-button-secondary-border: transparent;
  --theme-flat-button-secondary-hover-bg: var(--theme-text-main);
  --theme-flat-button-secondary-hover-color: white;
  --theme-flat-button-secondary-hover-border: transparent;
  --theme-flat-button-secondary-hover-opacity: 1;
  --theme-flat-button-secondary-active-bg: var(--theme-flat-button-secondary-hover-bg);
  --theme-flat-button-secondary-active-color: var(--theme-flat-button-secondary-hover-color);
  --theme-flat-button-secondary-active-border: var(--theme-flat-button-secondary-hover-border);
  --theme-flat-button-secondary-disabled-bg: var(--theme-flat-button-secondary-bg);
  --theme-flat-button-secondary-disabled-color: var(--theme-flat-button-secondary-color);
  --theme-flat-button-secondary-disabled-border: var(--theme-flat-button-secondary-border);
  --theme-flat-button-secondary-disabled-opacity: 1;

  /** Table */
  --theme-table-header-bg: var(--grey-4);
  --theme-table-header-color: var(--theme-text-dark);
  --theme-table-header-border: var(--grey);
  --theme-table-cell-border: var(--grey-bg);
  --theme-table-row-selected-bg: var(--grey-3);

  /* */
  --theme-user-avatar-bg: #cad2da;

  /* Radio */
  --theme-radio-unselected-border: #807f7f;
  --theme-radio-selected-border: var(--theme-primary);
  --theme-radio-icon-color: var(--theme-text-main);

  /* Pagination */
  --theme-pagination-color: var(--theme-primary);
  --theme-pagination-bg: var(--theme-card-bg);
  --theme-pagination-disabled-color: var(--theme-input-border);
  --theme-pagination-disabled-bg: var(--theme-card-bg);
  --theme-pagination-active-color: var(--theme-card-bg);
  --theme-pagination-active-bg: var(--theme-text-accent);
}
