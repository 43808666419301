html {
  --mdc-checkbox-state-layer-size: 2.5rem;
}

body {
  .mat-card {
    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }
  }

  .mat-button {
    .mat-button-wrapper {
      svg {
        margin-right: 0.5rem;
      }

      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .normalize-dialog {
    height: 100%;

    .mat-dialog-container {
      padding: 0;
      overflow: hidden;
    }

    &-overflow {
      .mat-dialog-container {
        height: auto;
        padding: 0;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  .layout .mat-drawer-container {
    background-color: var(--theme-main-bg);
  }

  .mat-drawer-side {
    border: none;
  }

  mat-checkbox.mat-checkbox-full-width {
    .mat-checkbox-layout {
      width: 100%;

      .mat-checkbox-label {
        width: 100%;
      }
    }
  }

  .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
    color: var(--theme-text-main);
  }

  .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
  .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
  .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: var(--theme-text-accent);
  }

  .mat-mdc-tab-body-wrapper {
    flex: 1 1 auto;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 3rem;
    padding: 0.75rem;
  }

  .mat-mdc-icon-button {
    --mdc-icon-button-icon-size: 1.5rem;
  }

  .mat-icon {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    stroke: currentColor;
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    height: 3rem;
    width: 3rem;
  }

  .mat-mdc-form-field-icon-prefix > .mat-icon,
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0.75rem;
  }

  .mat-svg-icon-prefix {
    --icon-size: 1.5rem;
    padding-left: 0.625rem;
  }

  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-suffix {
    --icon-size: 1.5rem;
  }

  .value-field {
    &__suffix {
      display: flex;
      align-items: center;
    }
  }

  .value-field__suffix,
  .mat-mdc-form-field-icon-suffix {
    .app-icon-button {
      &:last-child {
        margin-right: 0.75rem;
      }
    }
  }

  .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
  .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: var(--theme-accent);
  }
}

mat-dialog-container {
  max-height: 90vh !important;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 0.625rem !important;
}

.mat-icon {
  svg {
    display: block;
  }
}

.mat-drawer-container {
  --mat-sidenav-content-text-color: var(--theme-text-dark);
}
