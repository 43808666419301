.mat-datepicker-content {
  --mat-datepicker-calendar-date-text-color: var(--theme-text-dark);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--theme-accent);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--grey-4);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--grey-2);

  .mat-calendar-body-cell-content {
    border-radius: 0.25rem;
  }

  .mat-calendar-body-range-end:not(.mat-calendar-body-in-comparison-range)::before,
  .mat-calendar-body-range-end::after,
  .mat-calendar-body-comparison-end:not(.mat-calendar-body-comparison-bridge-end)::before,
  .mat-calendar-body-comparison-end::after,
  .mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
