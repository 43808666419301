body {
  .mat-mdc-checkbox {
    &.checkbox-reversed {
      .mdc-form-field {
        width: 100%;
        flex-direction: row-reverse;

        > label {
          padding-left: 0;
          padding-right: 0.25rem;
        }
      }
    }
  }
}
