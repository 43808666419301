@use '@angular/material' as mat;

$sidenav__width: 13.125rem;
$sidenav__width-collapsed: 1.5rem;

$sidenav-item__left-padding: 2rem;
$sidenav-item__right-padding: 1rem;
$sidenav-item__left-padding-step: 0.5rem;

$sidenav-submenu_sel: null;

.sidenav {
  background: var(--theme-sidebar-bg);
}

.sidenav-container {
  overflow: visible !important;
}

.mat-drawer-inner-container {
  overflow: visible !important;
}

.mat-drawer-side {
  border-right: 0;

  &:not(.open) {
  }

  &.sidenav-collapsed.mat-drawer-opened {
    transform: translate3d(-#{$sidenav__width - $sidenav__width-collapsed}, 0, 0) !important;
    transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1) !important;

    .sidenav {
      width: $sidenav__width;
    }

    & + .mat-drawer-content,
    & + .cdk-visually-hidden + .mat-drawer-content,
    & + .cdk-visually-hidden + .cdk-visually-hidden + .cdk-visually-hidden + .mat-drawer-content {
      margin-left: $sidenav__width-collapsed !important;
    }

    .header {
      .logo {
        transform: translate3d(-50px, 0, 0);
        transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) 25ms;
      }

      .toggle svg-icon {
        transform: scaleX(-1);
      }
    }

    .menu {
      .heading {
        .name {
          transform: translate3d(-50px, 0, 0);
          transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) 40ms;
        }

        .toggle {
          transform: translate3d(-50px, 0, 0);
          transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) 40ms;
        }
      }

      .sidenav-item {
        justify-content: flex-end;

        .name {
          transform: translate3d(-50px, 0, 0);
          width: 0;
          opacity: 0;
          transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) 40ms;
        }

        .icon,
        .letter-icon {
          transform: translate3d(8px, 0, 0);
          transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        }
      }

      .sub-menu {
        .dropdown-indicator {
          transform: translate3d(-10px, 0, 0);
          transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        }
      }
    }

    &.open {
      transform: translate3d(0, 0, 0) !important;

      .header .actions,
      .header .logo {
        transform: translate3d(0, 0, 0);
      }

      .menu {
        .heading {
          .name,
          .toggle {
            transform: translate3d(0, 0, 0);
          }
        }

        .sidenav-item {
          .name {
            opacity: 1;
            width: 14rem;
            transform: translate3d(0, 0, 0);
          }

          .icon,
          .letter-icon {
            transform: translate3d(0, 0, 0);
          }
        }

        .sub-menu .dropdown-indicator {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

.sidenav-item-container .sub-menu {
  position: relative;
  padding: 0;
  overflow: hidden;
}

.sidenav-item-container {
  > .sidenav-item {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0 $sidenav-item__right-padding 0 $sidenav-item__left-padding;
    color: var(--theme-sidebar-color);

    &:hover {
      color: var(--theme-sidebar-active);
    }

    &.active {
      color: var(--theme-sidebar-active);
    }
  }

  .sub-menu {
    .sidenav-item {
      color: var(--theme-sidebar-color);
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.125rem;
    }
  }

  @for $i from 1 through 6 {
    $sidenav-submenu_sel: if(
      $i == 1,
      '> .sub-menu .sidenav-item-container',
      selector-nest($sidenav-submenu_sel, '> .sub-menu .sidenav-item-container')
    );

    #{$sidenav-submenu_sel} {
      > .sidenav-item {
        padding-left: $sidenav-item__left-padding + $sidenav-item__left-padding-step * $i;
      }
    }
  }
}
