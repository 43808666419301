@import 'typography';

.editor {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__grid {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem 1.5rem;

    &._full-size {
      display: flex;

      > * {
        flex: 1 1 auto;
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__description {
    color: var(--theme-text-secondary);
  }

  &__label {
    color: var(--theme-text-secondary);
    font-size: 0.625rem;
    line-height: 0.75rem;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin-top: auto;

    &._left {
      justify-content: flex-start;
    }

    &._right {
      justify-content: flex-end;
    }

    .app-flat-button {
      width: 15rem;
      height: 3rem;
    }
  }

  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .app-flat-button {
      padding: 0;
      width: 15rem;
      height: 2.375rem;
    }
  }

  .form-control {
    min-width: 0;
    flex: 0 0 auto;

    > mat-form-field {
      width: 100%;
    }
  }

  .view-control {
    align-self: stretch;
  }
}
