@import 'typography';

.main-table {
  width: auto;
  min-width: 100%;

  th.mat-mdc-header-cell {
    background: var(--theme-table-header-bg);
    font-weight: 400;
    color: var(--theme-table-header-color);
    @include typography-subtitle;
  }

  .mdc-data-table__cell,
  .mdc-data-table__row:last-child .mdc-data-table__cell {
    border: 1px solid var(--theme-table-cell-border);
    @include typography-table-text;
  }

  .mdc-data-table__header-cell {
    border: 1px solid var(--theme-table-header-border);
  }

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: 0 1rem;
  }

  &.mat-mdc-table {
    tr.main-table-details-row {
      height: 0;
    }

    .mdc-data-table__header-row,
    .mdc-data-table__row {
      height: 3.125rem;
    }
  }

  .mdc-data-table__cell,
  .mdc-data-table__row:last-child .mdc-data-table__cell {
    &.cdk-column-empty {
      border-right: none;
    }

    &.cdk-column-expandedDetail {
      border-left: none;
      padding-left: 0;
    }
  }

  .cdk-column-actions {
    color: var(--theme-text-secondary);
    white-space: nowrap;

    .app-icon-button {
      width: 2.5rem;
      height: 2.5rem;
      --icon-size: 1.25rem;
      color: #3593d1;
    }
  }

  .cdk-column-num,
  .cdk-column-expand,
  .cdk-column-checkbox {
    width: 1%;
  }

  .mat-column-id {
    white-space: nowrap;
  }

  &._selectable {
    tr {
      cursor: pointer;
    }
  }

  tr {
    &._selected {
      background: var(--theme-table-row-selected-bg) !important;
    }
  }

  .mat-mdc-row {
    color: var(--theme-text-dark);
  }
}

.main-table-details {
  padding: 1rem 3.125rem 1.5rem 0;

  .subtitle {
    @include typography-subtitle-2;
    margin-bottom: 0.625rem;
    margin-top: 0;
  }

  .mat-mdc-table {
    border-spacing: 0;

    &._stretch {
      width: 100%;
    }

    &:not(._selectable) {
      .mdc-data-table__row:hover {
        background: transparent !important;
      }
    }
  }
}

.details-link {
  display: flex;
  align-items: center;
  gap: 0.125rem;
  color: var(--blue-2);
  text-decoration: none;
  background: none;
  appearance: none;
  cursor: pointer;
  border: none;
  --icon-size: 1rem;

  svg-icon {
    transform: scaleX(-1);
  }
}
