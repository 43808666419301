.snack-bar, .snack-bar.mat-mdc-snack-bar-container {
  white-space: pre-line;

  .mdc-snackbar__surface {
    box-shadow: -1px 3px 20px 1px #212C3726;
    border-radius: 0.5rem;
  }

  &._success {
    .mdc-snackbar__surface {
      background-color: var(--theme-alert-success-bg);
      border: 1px solid var(--theme-alert-success-color);
    }

    .mdc-snackbar__label {
      color: var(--theme-alert-success-color);
    }
  }

  &._danger {
    .mdc-snackbar__surface {
      background-color: var(--theme-alert-danger-bg);
      border: 1px solid var(--theme-alert-danger-color);
    }

    .mdc-snackbar__label {
      color: var(--theme-alert-danger-color);
    }
  }

  &._warning {
    .mdc-snackbar__surface {
      background-color: var(--theme-alert-warn-bg);
      border: 1px solid var(--theme-alert-warn-color);
    }

    .mdc-snackbar__label {
      color: var(--theme-alert-warn-color);
    }
  }

  &._info {
    .mdc-snackbar__surface {
      background-color: var(--theme-alert-info-bg);
      border: 1px solid var(--theme-alert-info-color);
    }

    .mdc-snackbar__label {
      color: var(--theme-alert-info-color);
    }
  }
}
