body {
  .mdc-tab {
    &.mdc-tab--active {
      .mdc-tab__text-label {
        font-weight: 600;
      }
    }
  }

  .mat-mdc-tab-labels, .mat-mdc-tab-links {
    border-bottom: 1px solid #DEE1E2;
  }

  .mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
    --mat-tab-header-label-text-size: 1rem;

    --mdc-tab-indicator-active-indicator-color: var(--theme-primary);
    --mat-tab-header-disabled-ripple-color: transparent;
    --mat-tab-header-pagination-icon-color: #000;
    --mat-tab-header-inactive-label-text-color: var(--theme-text-main);
    --mat-tab-header-active-label-text-color: var(--theme-text-main);
    --mat-tab-header-active-ripple-color: transparent;
    --mat-tab-header-inactive-ripple-color: transparent;
    --mat-tab-header-inactive-focus-label-text-color: var(--theme-text-main);
    --mat-tab-header-inactive-hover-label-text-color: var(--theme-text-main);
    --mat-tab-header-active-focus-label-text-color: var(--theme-text-accent);
    --mat-tab-header-active-hover-label-text-color: var(--theme-text-accent);
    --mat-tab-header-active-focus-indicator-color: var(--theme-primary);
    --mat-tab-header-active-hover-indicator-color: var(--theme-primary);
  }
}

.tab-content-padding {
  padding-top: 1rem;
}

