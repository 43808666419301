body {
  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-disabled-selected-icon-color: var(--theme-radio-selected-border);
    --mdc-radio-disabled-unselected-icon-color: var(--theme-radio-unselected-border);
    --mdc-radio-unselected-hover-icon-color: var(--theme-radio-selected-border);
    --mdc-radio-unselected-icon-color: var(--theme-radio-icon-color);
    --mdc-radio-unselected-pressed-icon-color: var(--theme-radio-icon-color);
    --mdc-radio-selected-focus-icon-color: var(--theme-radio-icon-color);
    --mdc-radio-selected-hover-icon-color: var(--theme-radio-icon-color);
    --mdc-radio-selected-icon-color: var(--theme-radio-icon-color);
    --mdc-radio-selected-pressed-icon-color: var(--theme-radio-icon-color);
    --mat-radio-ripple-color: transparent;
    --mat-radio-checked-ripple-color: transparent;
    --mat-radio-disabled-label-color: var(--theme-text-main);
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
  .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--theme-radio-selected-border);
  }
}
