.mdc-button {
  &.mdc-button--outlined, &.mdc-button--unelevated {
    &:not(.mat-unthemed) {
      text-transform: uppercase;
      font-weight: 500;
      border-radius: 0;
    }

  }
}
